<template>
    <div>

        <b-button variant="danger" class="mt-0 mb-1" @click="openTaskModal()">
            <feather-icon icon="EditIcon" class="mr-25" />
            <span>Edit</span>
        </b-button>

        <b-modal scrollable hide-footer
            id="taskModel"
            ref="taskModel"
            title="Edit Task"
            no-close-on-backdrop
            @hide="hideTaskModal"
            @show="hideTaskModal"
            size="lg"
        >
        <div v-if="item != null">
            
            <b-row class="pb-1">

                <b-col md="12">
                    <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body"> {{error_message}} </div>
                </b-alert>

                <b-form @submit.prevent="updateTask">
                    <b-row>
                        <b-col :md="form.periodic != null ? '6' : '12'" >
                            <b-form-group label="Status" class="required">
                                <b-form-select v-model="form.task_status">
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                <b-form-select-option value="open">Open</b-form-select-option>
                                <b-form-select-option value="in_progress">In Progress</b-form-select-option>
                                <b-form-select-option value="validate" >Validate</b-form-select-option>
                                <b-form-select-option value="closed">Closed</b-form-select-option>
                                <b-form-select-option value="failed">Failed</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" v-if="form.periodic != null">
                            <b-form-group label="Shift" class="required">
                                <b-form-select v-model="form.shift">
                                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                                        <b-form-select-option :value="shift" v-for="shift in shifts" :key="shift">{{shift}}</b-form-select-option>
                                </b-form-select>
                                
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="this.form.periodic != null">
                        <b-col md="6">
                            <b-form-group label="PDF Report Date" class="required"> 
                                <b-form-datepicker
                                    v-model="form.task_date"
                                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                    locale="en"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col md="6" >
                            <b-form-group label="Start Date" class="required"> 
                                <b-form-datepicker
                                    v-model="form.start"
                                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                    locale="en"
                                    :disabled="item.severality != null"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="6" >
                            <b-form-group label="End Date" class="required">
                                <b-form-datepicker
                                    v-model="form.end"
                                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                    locale="en"
                                    :disabled="item.severality != null"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1 mb-1">

                        <b-col md="6">
                        
                        <b-form-group
                            label=""
                            class="margin_bottom_zero_mobile"
                        >
                            <b-form-checkbox
                            v-model="form.planned_time_modify"
                            value="yes"
                            unchecked-value="no"
                            class="custom-control-warning"
                            @change="form.plannedStartTime = '00:00'"
                            >
                            Planned Start Time
                            </b-form-checkbox>
                            
                        </b-form-group>
                        </b-col>

                        
                    
                    </b-row>

                    <b-row >
                        <b-col md="6" v-if="form.planned_time_modify == 'yes'">
                            <b-form-group label="Planned Start Time" class="">
                                <flat-pickr
                                    v-model="form.plannedStartTime"
                                    class="form-control"
                                    :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col :md="form.planned_time_modify == 'yes' ? 6 : 12" >
                            <b-form-group label="Training Content" class="margin_bottom_zero_mobile">
                                <b-form-select v-model="form.training">
                                    <b-form-select-option value=''>Select</b-form-select-option>
                                    <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>

                        
                    </b-row>

                    <b-row class="mt-1">
                        <b-col md="6">
                            <b-form-group label="">
                                <b-form-checkbox 
                                    v-model="form.start_modify"
                                    value="yes"
                                    unchecked-value="no"
                                    class="custom-control-warning"
                                    @change="form.startTime = '00:00'"
                                    :disabled="item.severality != null"
                                >
                                    Start Time
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="">
                                <b-form-checkbox
                                    v-model="form.end_modify"
                                    value="yes"
                                    unchecked-value="no"
                                    class="custom-control-warning"
                                    @change="form.endTime = '00:00'"
                                    :disabled="item.severality != null"
                                >
                                    End Time
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row >
                        <b-col md="6" v-if="form.start_modify == 'yes'">
                            <b-form-group label="Start Time" class="">
                                <flat-pickr
                                    v-model="form.startTime"
                                    class="form-control"
                                    :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="6" v-if="form.end_modify == 'yes'">
                            <b-form-group label="End Time" class="">
                                <flat-pickr
                                    v-model="form.endTime"
                                    class="form-control"
                                    :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <b-row >
                        <b-col md="6" >
                            <b-form-group label="Crew" class="required">

                                <v-select
                                    v-model="form.crew"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    label="full_name"
                                    :options="allCrews"
                                    placeholder="Select"
                                    :close-on-select=false
                            :clear-on-select=false
                                />

                            </b-form-group>
                        </b-col>

                        <b-col md="6" v-if="form.task_status == 'validate' || form.task_status == 'closed'">                
                            <b-form-group label="Completed By" class="required" >
                                <b-form-select v-model="form.completed_by">
                                <b-form-select-option value='' disabled>Select</b-form-select-option>
                                
                                    <b-form-select-option-group :label="getCapitalize(role.label)" v-for="(role,i) in allUsers" :key="role.label">

                                        <b-form-select-option :value="user._id" v-for="user in role.options" :key="user._id">{{user.full_name}}</b-form-select-option>

                                    </b-form-select-option-group>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="form.show_group == 'yes'">

                        <b-col md="12">
                            <b-form-group label="Group" class="required margin_bottom_zero_mobile" >
                                <v-select
                                v-model="form.group"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="group_array"
                                multiple
                                placeholder="Select"
                                :close-on-select=false
                            :clear-on-select=false
                                />

                                <span class="text-warning" @click="openGroupModel()">
                                <feather-icon icon="PlusCircleIcon" />
                                <span class="align-middle ml-50" style="cursor: pointer">Add Group</span>
                                </span>
                            </b-form-group>
                        </b-col>

                    </b-row>

                    <b-row class="" v-if="form.task_status == 'failed' && form.no_photo_reporting == 'yes'">
                        <b-col md="12">
                            <b-form-group label="Reason" class="required">
                                <b-form-textarea placeholder="upto 500 characters" rows="3" v-model="form.reason_for_yes"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row class="" v-if="form.task_status == 'failed' && form.no_photo_reporting == 'no'">
                        <b-col md="12">
                            <b-form-group label="Reason" class="required">
                                <b-form-textarea placeholder="upto 500 characters" rows="3" v-model="form.reason_for_no"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class=" margin_top_zero_mobile" v-if="this.form.periodic != null">
                        <b-col md="12">
                            <b-form-group label="Description" class="required">
                                <b-form-textarea placeholder="upto 500 characters" rows="2" v-model="form.description"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
                                Submit
                            </b-button>
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hideTaskModel();">
                                Cancel
                            </b-button>
                        </b-col>
                    </b-row>

                </b-form>
                </b-col>

            </b-row> 

        </div>
            <b-modal
                id="add-group"
                ref="add-group"
                cancel-variant="outline-secondary"
                ok-variant="outline-warning"
                ok-title="Submit"
                cancel-title="Close"
                centered
                title="Add Group"
                @show="resetGroup"
                @hidden="resetGroup"
                @ok="addGroup"
            >

                <b-alert v-model="popShowDismissibleAlertGroup" variant="danger" dismissible class="mb-1" >
                    <div class="alert-body">
                    {{error_message_group}}
                    </div>
                </b-alert>

                <b-form>
                    <b-form-group>
                        <label for="email">Group Name:</label>
                        <b-form-input placeholder="" v-model="group_name" />
                    </b-form-group>
                </b-form>
            </b-modal>
        </b-modal>

    </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormSelectOptionGroup,BFormDatepicker,BFormCheckbox,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select';
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Bus from "../../../event-bus";

var moment = require('moment-timezone');
export default {
    components: {
        BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormDatepicker,flatPickr,BFormCheckbox,vSelect,VueSimpleSuggest,DatePicker,BBreadcrumb,BFormSelectOptionGroup
    },
  
    data() {
        return {
        allCrews:[],
        supervisors:[],
        trainings:[],
        clearButton:true,
        item:null,
        showDismissibleAlert:false,
        popshowDismissibleAlert:false,
        popShowDismissibleAlertGroup:false,
        error_message:'',
        error_message_group:'',
        doc_icon: require('@/assets/images/doc.png'),
        clientSign : {
            image:null,
            name:'',
        },
        supervisorSign : {
            image:null,
            name:'',
        },
        shifts:[],

        form : {
            task_status : '',
            shift:'',
            start:null,
            end:null,
            task_date:null,
            crew:[],
            training:null,
            description:'',
            periodic:null,
            no_photo_reporting:'no',
            startTime:'',
            endTime:'',
            start_modify:'no',
            end_modify:'no',
            reason_for_yes:'',
            reason_for_no:'',
            plannedStartTime:'',
            completed_by:'',
            planned_time_modify:'no',
            show_group:'no',
            group:[],
        },
        openedSignModel:null,
        allUsers: [],
        group_name:'',
        group_array:[]
        }
    },

    directives: {
        Ripple,
    },

    methods: {
        hideTaskModal(){
            this.openedTaskModal = null;
            this.showDismissibleAlert = false;
            this.taskDetail();
        },
        
        openTaskModal(){
            if (this.openedTaskModal == null) {
                this.$refs['taskModel'].show();
            }
        },
        
        hideTaskModel(){
            this.showDismissibleAlert = false;
            this.$refs['taskModel'].hide();
        },

        showAlert() {
                
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: this.error_message,
                showConfirmButton: false,
                timer: 3000
            })
        },

        variantColor(status){
            if (status == 'open') {
            return 'primary';
            }else if(status == 'in_progress'){
            return 'warning'
            }else if(status == 'validate'){
            return 'info'
            }else if(status == 'failed'){
            return 'danger'
            }else{
            return 'success';
            }
        },

        taskDetail(){
            return this.$store.dispatch(POST_API, {
                data:{
                    id:this.$route.params.id,
                },
                api: '/api/task-status-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                } else {
                    
                    this.item = this.$store.getters.getResults.data;

                    
                    this.form.no_photo_reporting = this.item.site.no_photo_reporting;

                    if (this.item.comments.length == 0) {
                        this.item.comments.push({comment:''});
                    }


                    this.allSiteCrews();
                    this.siteUser(this.item.site._id);

                    var shifts = [];

                    this.item.site.shifts.forEach(item => {
                        shifts.push(item.shift);
                    })
                    this.shifts = shifts;
                    
                    // populating data in fields
                    this.popshowDismissibleAlert = false;

                    this.form.reason_for_yes = '';
                    this.form.reason_for_no  = '';

                    this.form.task_status       = this.item.task_status;
                    this.form.shift             = this.item.shift;
                    this.form.start             = this.item.start;
                    this.form.end               = this.item.end;
                    this.form.crew              = this.item.crew;
                    this.form.training          = this.item.training ? this.item.training._id : '';
                    this.form.description       = this.item.description;
                    this.form.periodic          = this.item.periodic;
                    this.form.startTime         = this.item.startTime;
                    this.form.endTime           = this.item.endTime;
                    this.form.start_modify      = this.item.start_modify;
                    this.form.end_modify        = this.item.end_modify;
                    this.form.plannedStartTime  = this.item.plannedStartTime;
                    this.form.completed_by      = this.item.completed_by ? this.item.completed_by._id : '';
                    this.form.planned_time_modify = this.item.planned_time_modify;
                    this.form.group = this.item.group;
                    this.form.task_date = this.item.task_date;
                    
                    
                    
                    if (this.item.group && this.item.group.length > 0) {
                  
                      this.form.show_group = 'yes';

                      this.taskGroupSuggestion();

                    }


                    return this.item;
                }
            });
        },

        updateTask(){
            return this.$store.dispatch(POST_API, {
                data:{
                    items:this.form,
                    id:this.$route.params.id,
                },
                api: '/api/update-task-status-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    return Swal.fire({
                        position : 'center',
                        icon     : 'error',
                        title    : this.$store.getters.getErrors,
                        timer    : 3000,
                        showConfirmButton: false
                    });
                } else {
                    this.popshowDismissibleAlert = false;
                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Task Updated Successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        this.$refs['taskModel'].hide();
                        // Bus.$emit('update-data',this.form.task_status);
                        this.$route.params.tab_status = this.form.task_status;

                        this.goBack();

                    });

                    this.reason_for_yes = '';
                    this.reason_for_no = '';
                }
            });
        },

        goBack(){
            if (this.$route.params.page == 'periodic') {
                var filter = {
                    site          : this.$route.params.site,
                    location      : this.$route.params.location,
                    crew          : this.$route.params.crew,
                    frequencyData : this.$route.params.frequencyData,
                    group         : this.$route.params.group,
                    start         : this.$route.params.start,
                    end           : this.$route.params.end,
                    tab_status    : this.$route.params.tab_status
                };
                this.$router.push({ name: 'periodic-sub-tasks', params: filter });
            } else {
                var filter = {
                    site       : this.$route.params.site,
                    location   : this.$route.params.location,
                    type       : this.$route.params.type,
                    crew       : this.$route.params.crew,
                    start      : this.$route.params.start,
                    end        : this.$route.params.end,
                    tab_status : this.$route.params.tab_status
                };
                this.$router.push({ name: 'adhoc-tasks', params: filter });
            }   
        },

        customFormatter(date) {
            return moment(date).format('DD MMM YYYY');
        },

        trainingData(){
            return this.$store.dispatch(GET_API, {
                data:{ },
                api: '/api/all-trainings'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    //window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.trainings = data;
                    return this.trainings;
                }
            });
        },

        allSiteCrews(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site:this.item.site
                },
                api: '/api/all-crew'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    this.allCrews = data;

                    return this.allCrews;
                }
            });
        },

        /*siteUser(site){
      
          return this.$store.dispatch(POST_API, {
               data:{
                 site:site,
               },
               api: '/api/all-site-users'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    //window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.allUsers = data;

                    return this.allUsers;
                }
            });
        },*/
        siteUser(site){
      
          return this.$store.dispatch(POST_API, {
               data:{
                 site:site,
                 role:['administrator','admin','operation_manager', 'operation_executive',
                        'supervisor','crew','site_client','site_manager']
               },
               api: '/api/all-group-user-by-role'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    //window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.allUsers = data;

                    return this.allUsers;
                }
            });
        },
        taskGroupSuggestion(){
          return this.$store.dispatch(POST_API, {
               data:{
                 site:this.item.site._id
               },
               api: '/api/site-group-suggestions'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message_group = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    this.group_array  = this.$store.getters.getResults.data;
                    return this.group_array;
                }
            });
        },
        resetGroup(){
          this.form.group = [];
          this.group_name = '';
          this.popShowDismissibleAlertGroup = false;
        },
        openGroupModel(){
          this.form.group = [];
          this.$refs['add-group'].show();
        },
        addGroup(e){
          e.preventDefault();
          
          var data = {
            
              group_name:this.group_name,
              site:this.item.site._id,
              breaks: [{
                duration:''
              }]
          }

          return this.$store.dispatch(POST_API, {
               data:{
                 items:data
               },
               api: '/api/add-task-group'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message_group = this.$store.getters.getErrors;
                    this.popShowDismissibleAlertGroup = true;
                } else {
                    this.popShowDismissibleAlertGroup = false;
                    this.taskGroupSuggestion();
                    this.$refs['add-group'].hide();
                }
            });
        },
        getCapitalize(value){
            if (value) {
                value = value.replace(/_/g, ' ');

                value = value.split(' ');

                var arr = [];
                value.forEach(item => {
                    arr.push(item.charAt(0).toUpperCase() + item.slice(1))
                })

                return arr.join(' ');
            }else{
                return 'N/A';
            }
        }
    },
    mounted(){
        this.taskDetail();
        this.trainingData();
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
